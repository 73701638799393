import React from 'react';
import styled from "@emotion/styled";

const Wrapper = styled.div({
  margin: '2px 0 0 0',
});

const Head = styled.div({
  backgroundColor: 'black',
  color: 'gold',
  cursor: 'pointer',
  margin: '0 0 0 0',
  padding: '7px 11px',
  fontWeight: 'bold',
  '.plusminus': {
    float: 'right',
  }
});

const Body = styled.div({
  padding: '0px 8px',
  margin: '0px',
  // borderLeft: '1px solid #CCCCCC',
  // borderRight: '1px solid #CCCCCC',
  // borderBottom: '1px solid #CCCCCC',
  backgroundColor: '#f8e0e752',
  // color: 'white',
  li: {
    marginTop: '12px'
  },
  div: {
    margin: '15px',
    fontSize: '1.8rem',
    fontWeight: 'bold'
  }
});

const open = {
  maxHeight: '3000px',
  overflow: 'hidden',
  transition: 'max-height 1s linear',
};

const close = {
  maxHeight: '0px',
  overflow: 'hidden',
  padding: 0,
};

export default class Sale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      content: '',
    };
  }

  toggleOpen = (isOpen) => {
    this.setState({
      isOpen: !isOpen
    })
  }

  componentDidMount() {
    this.setState({
      content: this.props.children
    });
  }

  render () {
    const { isOpen, content } = this.state;
    return (
      <Wrapper>
        <Head
          onClick={() => this.toggleOpen(isOpen)}
        >
          {this.props.title}<span className="plusminus">{isOpen ? '-' : '+'}</span>
        </Head>
        <Body style={isOpen ? open : close}>{content}</Body>
      </Wrapper>
    );
  }
}